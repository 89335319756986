require('./bootstrap');

import 'lightbox2/dist/css/lightbox.css';
import intlTelInput from 'intl-tel-input';
import lightbox from 'lightbox2';

"use strict";
jQuery(function () {
    const menu = document.getElementById('main-nav');
    menu.addEventListener('shown.bs.offcanvas', function () {
        $('.content-container .close-button').addClass('d-none');
    });
    menu.addEventListener('hide.bs.offcanvas', function () {
        $('.content-container .close-button').removeClass('d-none');
    })

    $(document).on('click', '.nav-item .nav-link', () => {
        $('#main-nav.offcanvas.show .navbar-toggler-cross').click();
    });

    $('.img-preloader[data-image-src]').each(function() {
        $(this).imagePlaceholder();
    });

    $('input[type="tel"]').each(function() {
        intlTelInput($(this)[0], {
            initialCountry: 'sk',
            hiddenInput: 'phone-full',
            separateDialCode: true,
            preferredCountries: [
                'sk', 'cz', 'at', 'hu', 'pl', 'ua'
            ],
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js'
        });
    });

    $('select.autocomplete').ajaxSelectPicker({
        minLength: 3,
        // log: true,
        langCode: 'sk-SK',
        locale: {
            currentlySelected: 'Aktuálny výber',
            emptyTitle: 'Zadajte hľadaný výraz (aspoň 3 znaky)',
            errorText: 'Nastal problém vo výsledkoch hľadania',
            searchPlaceholder: 'Hľadaný výraz (aspoň 3 znaky)...',
            statusInitialized: 'Zadajte výraz pre vyhľadávanie',
            statusNoResults: 'Neboli nájdené žiadne záznamy',
            statusSearching: 'Prebieha hľadanie...',
            statusTooShort: 'Hľadaný výraz je príliš krátky (aspoň 3 znaky)'
        },
        ajax: {
            data: function() {
                let $form = this.plugin.$element.closest('form');
                let data = {};

                $form.find(':input').serializeArray().map((e) => {
                    data[e.name] = e.value;
                });

                return {
                    ...data,
                    q: '{{{q}}}',
                    ...this.plugin.$element.data('absAjaxRequestData')
                };
            }
        }
    });

    $('select.selectpicker').each(function() {
        $(this).toggleClass('option-selected', ($(this).val() != ''));
    });

    $('select.selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        $(this).toggleClass('option-selected', ($(this).val() != ''));
    });

    $('select[name="region"]').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        $('select[name="city"]').html('').selectpicker('refresh');
        // $('select[name="cadastral_area"]').html('').selectpicker('refresh');
    });

    $('select[name="city"]').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        let $form = $(this).closest('form');
        let data = {};
        let $selected = $('option:selected', this);
        let $regionSelect = $('select[name="region"]');

        $regionSelect.val($selected.data('region_id'));
        $regionSelect.selectpicker('refresh');

        $form.find(':input').serializeArray().map((e) => {
            data[e.name] = e.value;
        });

        /*
        $.ajax({
            dataType: 'json',
            method: 'post',
            url: $('select[name="cadastral_area"]').data('loadUrl'),
            data: data
        })
        .done(function(response) {
            let $cadastralAreaSelect = $('select[name="cadastral_area"]');
            $cadastralAreaSelect.html('');
            $.each(response, function (ix, area) {
                let option = $('<option/>', { value: area.value, text: area.text });
                $cadastralAreaSelect.append(option);
            });
            $cadastralAreaSelect.selectpicker('refresh');
        });
        */
    });

    lightbox.option({
        'albumLabel': '%1 / %2'
    })
});

/**
 * Create blurred placeholder until image loads.
 *
 * @return jQuery
 */
$.fn.imagePlaceholder = function()
{
    const $elm = this;
    const $small = $('img', $elm);
    var img = new Image();
    var subject = new Image();

    img.src = $small.attr('src');
    subject.src = $elm.attr('data-image-src');

    $(subject).addClass($elm.attr('data-image-class'));

    img.onload = function() {
        $small.addClass('loaded');
    };

    subject.onload = function() {
        $(subject).addClass('loaded');
        // $small.removeClass('loaded');
    };

    $(subject).appendTo($elm);

    return this;
};
